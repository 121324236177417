<template>
	<main
		class="row display-block max-width-grid padding-y-24 search"
		id="search-view"
	>
		<h2
			class="page__title margin-t-0 margin-b-12 padding-x-16 font-size-18 line-height-175
				bp-768:font-size-30 bp-768:line-height-150 bp-768:padding-x-24
				bp-1280:font-size-36"
		>
			Site Search
		</h2>
		<div class="gcse-search"></div>
	</main>
</template>

<script>
export default {
	name: 'Search',

	computed: {
		query: function() {
			return this.$route.query.q;
		},
	},

	methods: {
		append_gsce_script() {
			let gsce = document.createElement('script');
			gsce.setAttribute(
				'src',
				'https://cse.google.com/cse.js?cx=012352829746019138190:quat0vzlzqq',
			);
			gsce.setAttribute('async', '');
			document.getElementById('search-view').appendChild(gsce);
		},
	},

	created() {
		this.setMetaSidearm('Search', [
			{
				name: 'description',
				content: 'Search on NCAA.org',
			},
			{
				name: 'og:title',
				content: 'Search',
			},
			{
				name: 'og:description',
				content: 'Search on NCAA.org',
			},
			{
				name: 'twitter:title',
				content: 'Search',
			},
			{
				name: 'twitter:description',
				content: 'Search on NCAA.org',
			},
		]);
	},

	mounted() {
		this.append_gsce_script();
	},
};
</script>

<style lang="scss">
.page {
	&__title {
		box-shadow: inset 6px 0 0 var(--color-primary);
	}
	.gsc-control-cse {
		padding: 0 !important;
	}
}
</style>
